
import { defineComponent } from "vue";
import Phone from "@/components/reusable/EditPhoneAddition.vue";
import Laptop from "@/components/reusable/EditLaptopAddition.vue";
import Jewelry from "@/components/reusable/EditJewelryAddition.vue";
import Camera from "@/components/reusable/EditCameraAddition.vue";
import MusicalInstrument from "@/components/reusable/EditMusicalInstrumentAddition.vue";
import Inverter from "@/components/reusable/EditInverterAddition.vue";

import PhoneAddition from "@/components/reusable/PhoneAddition.vue";
import LaptopAddition from "@/components/reusable/LaptopAddition.vue";
import JewelryAddition from "@/components/reusable/JewelryAddition.vue";
import CameraAddition from "@/components/reusable/CameraAddition.vue";
import MusicalInstrumentAddition from "@/components/reusable/MusicalInstrumentAddition.vue";
import InverterAddition from "@/components/reusable/InverterAddition.vue";

export default defineComponent({
  name: "edit-house-insurance-contents-addition-component",
  components: {
    Phone,
    PhoneAddition,
    Laptop,
    LaptopAddition,
    Jewelry,
    JewelryAddition,
    Camera,
    CameraAddition,
    MusicalInstrument,
    MusicalInstrumentAddition,
    Inverter,
    InverterAddition
  },
  props: {
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    invoiceSummary: Object,
    policyId: String,
    phoneRatePublicId: String,
    laptopRatePublicId: String,
    cameraRatePublicId: String,
    inverterRatePublicId: String,
    musicalInstrumentRatePublicId: String,
    jewelryRatePublicId: String,
    grandPremium: Number,
    premiumCalculationRoute: String
  }
});
