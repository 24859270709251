
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "edit-addition-musical-content-component",
  components: { Field, ErrorMessage },
  props: {
    musicalInstrumentRate: Number,
    invoiceSummary: Object,
    policyId: String,
    musicalInstrumentRatePublicId: String,
    grandPremium: Number,
    premiumCalculationRoute: String,
  },
  data() {
    return {
      musicalInstrumentDetails: [
        {
          imageOfReceipt: "",
          brand: "",
          value: 0.0 as number,
        },
      ],
      totalPremium: 0.0 as number,
    };
  },
  created() {
    setTimeout(() => {
      this.musicalInstrumentDetails = this.invoiceSummary as any;
      this.totalPremium = this.grandPremium as number;
    }, 1500);
  },
  methods: {
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false) {
        const payload = {
          value: value,
          rate: event.target.accessKey,
        } as object;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );
              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    onLoadPremium(premium, rate, id) {
      setTimeout(() => {
        // Check if the phone item premium value is a number
        if (isNaN(premium) == false) {
          const payload = {
            value: premium,
            rate: rate,
          } as object;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.premiumCalculationRoute}`, payload)
              .then(({ data }) => {
                // Calculate premium from vehicle value
                variables.premiumCalculator(1, data.data, id);
                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                console.log(error);
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        } else {
          return;
        }
      }, 3000);
    },
    addDynamicField() {
      this.musicalInstrumentDetails.push({
        imageOfReceipt: "",
        brand: "",
        value: 0.0 as number,
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      $(".remove-music-" + counter).remove();

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    imageSelected(event, position) {
      this.musicalInstrumentDetails[position].imageOfReceipt =
        event.target.files[0];
    },
  },
});
