import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Phone = _resolveComponent("Phone")!
  const _component_PhoneAddition = _resolveComponent("PhoneAddition")!
  const _component_Laptop = _resolveComponent("Laptop")!
  const _component_LaptopAddition = _resolveComponent("LaptopAddition")!
  const _component_Jewelry = _resolveComponent("Jewelry")!
  const _component_JewelryAddition = _resolveComponent("JewelryAddition")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_CameraAddition = _resolveComponent("CameraAddition")!
  const _component_MusicalInstrument = _resolveComponent("MusicalInstrument")!
  const _component_MusicalInstrumentAddition = _resolveComponent("MusicalInstrumentAddition")!
  const _component_Inverter = _resolveComponent("Inverter")!
  const _component_InverterAddition = _resolveComponent("InverterAddition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.invoiceSummary.phoneDetails)
      ? (_openBlock(), _createBlock(_component_Phone, {
          key: 0,
          "phone-rate": _ctx.phoneRate,
          "phone-rate-public-id": _ctx.phoneRatePublicId,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.phoneDetails : '',
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["phone-rate", "phone-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_PhoneAddition, {
          key: 1,
          "phone-rate": _ctx.phoneRate,
          "phone-rate-public-id": _ctx.phoneRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["phone-rate", "phone-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"])),
    (_ctx.invoiceSummary.laptopOrTabletDetails)
      ? (_openBlock(), _createBlock(_component_Laptop, {
          key: 2,
          "laptop-rate": _ctx.laptopRate,
          "laptop-rate-public-id": _ctx.laptopRatePublicId,
          "invoice-summary": 
        _ctx.invoiceSummary ? _ctx.invoiceSummary.laptopOrTabletDetails : ''
      ,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["laptop-rate", "laptop-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_LaptopAddition, {
          key: 3,
          "laptop-rate": _ctx.laptopRate,
          "laptop-rate-public-id": _ctx.laptopRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["laptop-rate", "laptop-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"])),
    (_ctx.invoiceSummary.jewelryDetails)
      ? (_openBlock(), _createBlock(_component_Jewelry, {
          key: 4,
          "jewelry-rate": _ctx.jewelryRate,
          "jewelry-rate-public-id": _ctx.jewelryRatePublicId,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.jewelryDetails : '',
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["jewelry-rate", "jewelry-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_JewelryAddition, {
          key: 5,
          "jewelry-rate": _ctx.jewelryRate,
          "jewelry-rate-public-id": _ctx.jewelryRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["jewelry-rate", "jewelry-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"])),
    (_ctx.invoiceSummary.cameraDetails)
      ? (_openBlock(), _createBlock(_component_Camera, {
          key: 6,
          "camera-rate": _ctx.cameraRate,
          "camera-rate-public-id": _ctx.cameraRatePublicId,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.cameraDetails : '',
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["camera-rate", "camera-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_CameraAddition, {
          key: 7,
          "camera-rate": _ctx.cameraRate,
          "camera-rate-public-id": _ctx.cameraRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["camera-rate", "camera-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"])),
    (_ctx.invoiceSummary.musicalInstrumentDetails)
      ? (_openBlock(), _createBlock(_component_MusicalInstrument, {
          key: 8,
          "musical-instrument-rate": _ctx.musicalInstrumentRate,
          "musical-instrument-rate-public-id": _ctx.musicalInstrumentRatePublicId,
          "invoice-summary": 
        _ctx.invoiceSummary ? _ctx.invoiceSummary.musicalInstrumentDetails : ''
      ,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["musical-instrument-rate", "musical-instrument-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_MusicalInstrumentAddition, {
          key: 9,
          "musical-instrument-rate": _ctx.musicalInstrumentRate,
          "musical-instrument-rate-public-id": _ctx.musicalInstrumentRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["musical-instrument-rate", "musical-instrument-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"])),
    (_ctx.invoiceSummary.inverterDetails)
      ? (_openBlock(), _createBlock(_component_Inverter, {
          key: 10,
          "inverter-rate": _ctx.inverterRate,
          "inverter-rate-public-id": _ctx.inverterRatePublicId,
          "invoice-summary": _ctx.invoiceSummary ? _ctx.invoiceSummary.inverterDetails : '',
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["inverter-rate", "inverter-rate-public-id", "invoice-summary", "policy-id", "grand-premium", "premium-calculation-route"]))
      : (_openBlock(), _createBlock(_component_InverterAddition, {
          key: 11,
          "inverter-rate": _ctx.inverterRate,
          "inverter-rate-public-id": _ctx.inverterRatePublicId,
          "policy-id": _ctx.policyId,
          "grand-premium": _ctx.grandPremium,
          "premium-calculation-route": _ctx.premiumCalculationRoute
        }, null, 8, ["inverter-rate", "inverter-rate-public-id", "policy-id", "grand-premium", "premium-calculation-route"]))
  ]))
}